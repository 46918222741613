document.addEventListener("turbolinks:load", () => {
    if ($('div#event-attachments-attr').length > 0 && $('div#event-attachments-attr').data('pigeonhole').length > 0 && $("div.pigeonhole-iframe").text().length == 0) {
        var passcode = $('div#event-attachments-attr').data('pigeonhole');
        var sessionid = $('div#event-attachments-attr').data('pigeonhole-sessionid');

        (function(p,i,g,e,o,n,s){p[o]=p[o]||function(){(p[o].q=p[o].q||[]).push(arguments)},
            n=i.createElement(g),s=i.getElementsByTagName(g)[0];n.async=1;n.src=e;
            s.parentNode.insertBefore(n,s);})
            (window,document,'script','https://static.pigeonhole.at/widget/pigeon-widget.js','phl');
        phl("create", {
            width: "320px",
            height: "490px",
            passcode: passcode,
            className: "pigeonhole-iframe",
            sessionId: sessionid,
        });
    }
})