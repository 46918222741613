document.addEventListener("turbolinks:load", () => {
  if (window.location.pathname.indexOf("/downloads/") >= 0) {
    var searchParams = new URLSearchParams(window.location.search);
    var data_type = window.location.pathname.split('/')[2];
    var data_id = window.location.pathname.split('/')[3];
    var data_format = searchParams.get('data_format');

    if (data_type && data_id && data_format) {
      // add delay to show download page after logging in
      setTimeout(function () {
        window.location.href = "/downloads/download_data?data_type=" + data_type + "&data_id=" + data_id + "&data_format=" + data_format;
      }, 500);
    }
  }

  if (window.location.pathname.indexOf("/download_list/") >= 0) {
    $("#download_list_button").click(function () {
      setTimeout(function () {
        $("#download_list_button").prop('disabled', false);
      }, 500);
    });

    $('#select-all').click(function () {
      if (this.checked) {
        $(':checkbox').each(function () {
          this.checked = true;
        });
      } else {
        $(':checkbox').each(function () {
          this.checked = false;
        });
      }
    });

  }
})

