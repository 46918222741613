document.addEventListener("turbolinks:load", () => {
  if ($("body.dashboard").length > 0) {
    // get viewers count
    $.ajax({
      url: '/events/webcast_viewer_count',
      type: 'GET',
      success: function(response, status, xhr) {
        var has_data = 0;
        $("table.viewers-count tbody").html("");

        $.each(response, function(key, value) {
          has_data = 1;
          $("table.viewers-count tbody").append("<tr><td>" + key + "</td><td>" + value[0] + "</td><td>" + value[1] + "</td></tr>");
        });

        if (has_data == 0)
          $("table.viewers-count tbody").append("<tr><td colspan='3' class='text-center'>No Data</td></tr>");
      }
    });

    // get downloads count
    $.ajax({
      url: '/downloads/downloads_count',
      type: 'GET',
      success: function(response, status, xhr) {
        var has_data = 0;
        $("table.downloads-count tbody").html("");

        $.each(response, function(key, value) {
          $.each(value, function(k, v) {
            has_data = 1;
            $("table.downloads-count tbody").append("<tr><td>" + key + ": " + k + "</td><td>" + v + "</td></tr>");
          });
        });

        if (has_data == 0)
          $("table.downloads-count tbody").append("<tr><td colspan='2' class='text-center'>No Data</td></tr>");
      }
    });
	}
})

