document.addEventListener("turbolinks:load", () => {
	if($(".notice .alert-dismissible").is(":visible")){
		window.setTimeout(function() {
		    $(".notice .alert-dismissible").fadeOut(function(){
		        $(this).closest(".notice").remove(); 
		    });
		}, 5000);
	}
})

